<template>
  <div class="w-full py-8">
    <ConfirmPlan
      v-if="stripeId"
      :stripe-id="stripeId"
    />
    <div v-else>
      Plan missing from URL
    </div>
  </div>
</template>

<script>
import ConfirmPlan from '@components/Plans/ConfirmPlan'

export default {
  page: {
    title: 'Confirm plan'
  },

  components: {
    ConfirmPlan
  },

  computed: {
    /**
     * @return {string}
     */
    stripeId() {
      return this.$route.params.plan
    }
  }
}
</script>
