<template>
  <Modal
    :open="modalOpen"
    :can-close="false"
  >
    <div class="border-b-2 bg-white">
      <div class="flex items-center px-6 pb-6">
        <Icon
          width="42px"
          height="42px"
          view-box="0 0 80 80"
          class="mr-2"
        >
          <Celebration />
        </Icon>
        <h3 class="font-medium text-lg">
          Subscription confirmed!
        </h3>
      </div>
    </div>

    <div class="p-6">
      <p class="mb-6" >
        You’re now subscribed to the <strong class="text-lg">{{ planName }}</strong> plan.
      </p>
      <BaseButton
        :to="{ name : 'settings-plan' }"
        variant="gray"
      >
        Back to Settings
      </BaseButton>
    </div>
  </Modal>
</template>

<script>
import Modal from '@components/Modal'
import Icon from '@components/Icons/Icon'
import Celebration from '@components/Icons/Celebration'

export default {
  components: {
    Modal,
    Icon,
    Celebration
  },

  props: {
    modalOpen: {
      type: Boolean,
      default: false
    },

    planName: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
