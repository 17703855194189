<template>
  <div class="">
    <!-- Blurb -->
    <div
      v-if="product.marketingBlurbHtml"
      v-html="product.marketingBlurbHtml"
    >
    </div>
    <!-- Active jobs restriction -->
    <p
      v-if="product.restrictions.active_jobs"
      class="mt-2 font-medium"
    >
      {{ product.restrictions.active_jobs.jobs }} active {{ pluralize('job', product.restrictions.active_jobs.jobs) }}
    </p>
    <!-- Attempts per interval restriction -->
    <p
      v-if="product.restrictions.attempts_per_interval"
      class="mt-2 font-medium"
    >
      {{ numberFormat(product.restrictions.attempts_per_interval.attempts) }}
      test attempts per
      {{ product.restrictions.attempts_per_interval.interval }}
    </p>
  </div>
</template>

<script>
import { numberFormat } from '@utils/numberFormat'
import pluralize from 'pluralize'

export default {
  props: {
    product: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      numberFormat,
      pluralize
    }
  }
}
</script>
