<template>
  <div>
    <SettingsNavigation />
    <div v-show="cardState === cardStates.READY">
      <div class="sm:flex sm:justify-between max-w-screen-xl mx-auto px-6">
        <BaseCard class="sm:w-1/2 mb-8 sm:mr-8 sm:mb-0">
          <BaseCardHeader class="mb-6">
            <template slot="icon">
              <Receipt />
            </template>
            New plan
          </BaseCardHeader>

          <div v-if="product && plan">
            <div class="flex flex-col">
              <p class="pb-2">
                <span class="text-white rounded p-1 text-sm bg-secondary hover:bg-gray-primary-400 text-white">{{ product.name }}</span>
              </p>

              <p class="text-3xl leading-none font-bold mt-3">
                <span>
                  {{ formatCurrencyAuto(plan.amount / 100, plan.currency) }} <span class="text-gray-600 text-2xl font-normal">/{{ plan.recurringInterval }}</span>
                </span>
              </p>

              <p class="mt-3">
                <span
                  v-if="productsTaxPercentage"
                  class="text-gray-500 text-xs mr-3"
                >
                  +{{ productsTaxPercentage }}% VAT
                </span>
                <span
                  v-else
                  class="text-gray-500 text-xs mr-3"
                >
                  No VAT
                </span>
                <span class="text-gray-600 uppercase text-xs">{{ countryName }}</span>
              </p>

              <ProductMarketing
                :product="product"
                class="mb-6 mt-4 text-gray-600"
              >
              </ProductMarketing>

              <div>
                <label class="block mt-2">
                  <span class="text-gray-800">Discount code</span>
                  <input
                    v-model="coupon"
                    placeholder="Discount code"
                    class="form-input mt-1 text-sm block w-full h-12"
                  >
                </label>
              </div>

              <div v-if="specialFeatures.includes('enterVatNumber')">
                <label class="block mt-2">
                  <span class="text-gray-800">VAT Number (e.g. IE1234567)</span>
                  <input
                    v-model="vatNumber"
                    placeholder="VAT Number"
                    class="form-input mt-1 text-sm block w-full h-12"
                  >
                </label>
              </div>

              <hr class="mt-6">

              <table class="w-full my-4">
                <tr v-if="plan">
                  <td class="py-1">
                    <strong>{{ product.name }}</strong> plan
                  </td>
                  <td
                    v-if="preview && preview.planAmount"
                    class="py-1 text-right"
                  >
                    {{ formatCurrency(preview.planAmount / 100, plan.currency) }}
                  </td>
                  <td
                    v-else
                    class="py-1 text-right"
                  >
                    –
                  </td>
                </tr>
                <tr>
                  <td class="py-1">
                    Discount
                    <span v-if="preview && preview.discountPercentage">
                      {{ preview.discountPercentage }}%
                    </span>
                  </td>
                  <td
                    v-if="preview && preview.discountAmount"
                    class="py-1 text-right"
                  >
                    {{ formatCurrency(preview.discountAmount / 100, plan.currency) }}
                  </td>
                  <td
                    v-else
                    class="py-1 text-right"
                  >
                    –
                  </td>
                </tr>
                <tr class="border-t">
                  <td class="py-1">
                    Sub total
                  </td>
                  <td
                    v-if="preview && preview.subAmount"
                    class="py-1 text-right"
                  >
                    {{ formatCurrency(preview.subAmount / 100, plan.currency) }}
                  </td>
                  <td
                    v-else
                    class="py-1 text-right"
                  >
                    –
                  </td>
                </tr>
                <tr class="border-t">
                  <td
                    v-if="preview && preview.vatPercentage"
                    class="py-1"
                  >
                    VAT @ {{ preview.vatPercentage }}%
                  </td>
                  <td
                    v-else
                    class="py-1"
                  >
                    VAT
                  </td>
                  <td
                    v-if="preview && preview.vatAmount"
                    class="py-1 text-right"
                  >
                    {{ formatCurrency(preview.vatAmount / 100, plan.currency) }}
                  </td>
                  <td
                    v-else
                    class="py-1 text-right"
                  >
                    –
                  </td>
                </tr>
                <tr>
                  <td class="py-1 font-bold">
                    Total
                  </td>
                  <td
                    v-if="preview && preview.totalAmount"
                    class="py-1 text-right font-bold"
                  >
                    {{ formatCurrency(preview.totalAmount / 100, plan.currency) }}
                  </td>
                  <td
                    v-else
                    class="py-1 text-right"
                  >
                    –
                  </td>
                </tr>
              </table>
            </div>

            <div v-if="currentPlan && plan">
              <div
                v-if="migration === migrationStates.upgrade"
                class="flex items-center justify-between my-6"
              >
                <div>
                  <p>Your plan will be upgraded straight away</p>
                </div>
              </div>
              <div
                v-else-if="migration === migrationStates.downgrade"
                class="flex items-center justify-between my-6"
              >
                <div>
                  <p>Your plan will downgrade on {{ formatDate(parseISO(currentSubscription.currentPeriodEnd)) }}</p>
                  <p
                    v-if="currentPlan.tcAttemptsLimit > 0"
                    class="text-sm text-gray-600"
                  >
                    You can use your {{ currentPlan.tcAttemptsLimit }} test attempts limit until then
                  </p>
                  <p
                    v-else-if="currentPlan.tcAttemptsLimit === 0"
                    class="text-sm text-gray-600"
                  >
                    You can use your unlimited test attempts limit until then
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <p>Sorry, something’s gone wrong loading your plan.</p>

            <BaseButton
              :to="{ name: 'settings-plan' }"
              class="mt-6"
            >
              Choose a plan
            </BaseButton>
          </div>
        </BaseCard>

        <BaseCard class="sm:w-1/2">
          <BaseCardHeader
            bg="bg-secondary"
            class="mb-6"
          >
            <template slot="icon">
              <CreditCard />
            </template>
            Billing
          </BaseCardHeader>

          <div>
            <div
              v-if="billing && billing.card"
            >
              <div>
                <span class="text-xs bg-gray-600 rounded-sm p-1 mr-2 text-white">{{ billing.card.type }}</span>
                <span>
                  <span class="text-gray-300 text-sm">&bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull;</span>
                  {{ billing.card.lastFour }}
                </span>
              </div>
              <router-link
                :to="{ name: 'settings-billing' }"
                class="text-gray-500 underline hover:text-secondary duration-150"
              >
                Update
              </router-link>
            </div>
            <div v-show="billing && !billing.card">
              <form
                ref="form"
                class="text-sm mt-8"
                novalidate
                @submit.prevent
              >
                <div>
                  <label class="block">
                    <span class="text-gray-800">Name on card</span>
                    <input
                      v-model="cardholderName"
                      placeholder="Name on card"
                      class="form-input mt-1 text-sm block w-full h-12"
                    >
                  </label>
                </div>

                <label class="block mt-6">
                  <span class="text-gray-800">Card details</span>
                  <div
                    id="card"
                    class="form-input mt-1 text-sm w-full text-gray-500 px-2 py-4"
                  ></div>
                  <ErrorsInline v-if="cardError">
                    <span>{{ cardError }}</span>
                  </ErrorsInline>
                </label>

                <div>
                  <BaseButton
                    :loading="isProcessing"
                    :disabled="!canProcessForm"
                    class="w-full mt-6"
                    @click="processForm()"
                  >
                    {{ confirmLabel }}
                  </BaseButton>
                </div>

                <p
                  v-if="errorMessage"
                  class="mt-4"
                >
                  {{ errorMessage }}
                </p>
              </form>
            </div>
            <div v-if="billing && billing.card">
              <p
                v-if="plan"
                class="text-center text-gray-800 mt-10"
              >
                You have selected the <strong class="text-lg">{{ product.name }}</strong> plan
              </p>
              <BaseButton
                :loading="isProcessing"
                :disabled="!canProcessForm"
                class="w-full mt-6"
                @click="processForm()"
              >
                {{ confirmLabel }}
              </BaseButton>
              <p
                v-if="errorMessage"
                class="mt-4"
              >
                {{ errorMessage }}
              </p>
            </div>
          </div>
          <div v-if="!billing">
            <Loader />
          </div>
        </BaseCard>
      </div>

      <div v-if="plan">
        <SubscriptionConfirmedModal
          :modal-open="subscriptionConfirmed"
          :plan-name="plan.name"
        />

        <ChangePlanConfirmedModal
          :modal-open="changePlanActionConfirmed"
          :migration-state="migration"
          :migration-states="migrationStates"
          :plan-name="plan.name"
        />

        <SubscriptionConfirmedModal
          :modal-open="subscriptionConfirmed"
          :plan-name="plan.name"
        />
      </div>
    </div>
    <div v-if="cardState === cardStates.LOADING">
      <Loader />
    </div>
    <div v-else-if="cardState === cardStates.ERROR">
      Error
    </div>
  </div>
</template>

<script>
import ChangePlanConfirmedModal from '@components/Plans/ChangePlanConfirmedModal'
import CreditCard from '@components/Icons/CreditCard'
import Loader from '@components/Loader'
import ProductMarketing from '@components/Plans/ProductMarketing'
import Receipt from '@components/Icons/Receipt'
import SettingsNavigation from '@components/Settings/SettingsNavigation'
import SubscriptionConfirmedModal from '@components/Plans/SubscriptionConfirmedModal'

import billingApi from '@api/billing'
import subscriptionsApi from '@api/subscriptions'
import { parseISO } from 'date-fns'
import { formatCurrencyAuto, formatCurrency } from '@utils/currency'
import { formatDate } from '@utils/formatDate'
import { bingSubscription } from '@utils/bingUet'
import countries from '@groupResources/lists/countries'

import { mapGetters } from 'vuex'

const cardStates = {
  LOADING: 'loading',
  ERROR: 'error',
  READY: 'ready'
}

const migrationStates = {
  new: 'NEW',
  upgrade: 'UPGRADE',
  downgrade: 'DOWNGRADE',
  transfer: 'TRANSFER'
}

// eslint-disable-next-line
const stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_TC)
const elements = stripe.elements()
const elementStyle = {
  base: {
    color: '#15151D',
    fontSize: '14px',
    fontFamily: 'Circular, "Helvetica Neue", Helvetica, Arial, sans-serif'
  },
  invalid: {
    color: '#E80404'
  }
}

export default {
  components: {
    ChangePlanConfirmedModal,
    CreditCard,
    Loader,
    ProductMarketing,
    Receipt,
    SettingsNavigation,
    SubscriptionConfirmedModal
  },

  props: {
    stripeId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      cardStates,
      cardState: cardStates.LOADING,

      card: null,
      cardError: null,
      cardComplete: false,
      cardholderName: '',

      coupon: '',
      countryCode: null,

      countries,
      formatDate,
      parseISO,
      formatCurrencyAuto,
      formatCurrency,
      migrationStates,

      subscriptionConfirmed: false,
      changePlanActionConfirmed: false,

      planPricing: null,
      isProcessing: false,

      billing: null,
      preview: null,

      errorMessage: null,
      supportEmail: process.env.VUE_APP_SUPPORT_EMAIL,

      vatNumber: ''
    }
  },

  computed: {
    ...mapGetters({
      // New products system
      haveProductsLoaded: 'products/haveProductsLoaded',
      products: 'products/products',
      productsCountryCode: 'products/countryCode',
      productsTaxPercentage: 'products/taxPercentage',

      // Old plans system
      havePlansLoaded: 'plans/havePlansLoaded',
      legacyPlans: 'plans/tcPlans',

      haveSubscriptionsLoaded: 'subscriptions/haveSubscriptionsLoaded',
      currentSubscription: 'subscriptions/currentSubscription',
      organisationId: 'employers/organisationId',
      userId: 'employers/id',
      specialFeatures: 'organisations/specialFeatures'
    }),

    /**
     * @return {Object}
     */
    product() {
      if (!this.products) {
        return
      }
      return this.products.find(product => {
        return product.plans.find(plan => {
          return plan.stripeId === this.stripeId
        })
      })
    },

    /**
     * @return {Object}
     */
    plan() {
      if (!this.product) {
        return
      }

      return this.product.plans.find(product => product.stripeId === this.stripeId)
    },

    /**
     * @return {String}
     */
    confirmLabel() {
      if (!this.haveSubscriptionsLoaded) {
        return ''
      }
      if (this.currentPlan) {
        if (this.migration === migrationStates.upgrade) {
          return 'Upgrade my plan'
        } else if (this.migration === migrationStates.downgrade) {
          return 'Downgrade my plan'
        }
      }
      return 'Start plan'
    },

    /**
     * @return {Boolean}
     */
    canProcessForm() {
      return this.plan && ((this.billing && this.billing.card) || this.cardComplete) && this.preview
    },

    /**
     * @return {string}
     */
    migration() {
      if (!this.haveSubscriptionsLoaded || !this.plan) {
        return null
      }
      if (!this.currentSubscription) {
        return migrationStates.new
      }

      if (this.currentPlan && this.currentPlan.basePrice > this.plan.basePrice) {
        return migrationStates.downgrade
      } else if (this.currentPlan && this.currentPlan.basePrice < this.plan.basePrice) {
        return migrationStates.upgrade
      }

      return migrationStates.transfer
    },

    /**
     * @return {Object}
     */
    currentPlan() {
      if (!this.currentSubscription) {
        return
      }
      return this.products.find(product => {
        return product.plans.find(plan => {
          return plan.stripeId === this.currentSubscription.stripeId
        })
      })
    },

    /**
     * @return {string}
     */
    countryName() {
      let country = this.countries.find(country => {
        return country.value === this.countryCode
      })
      if (!country) {
        return
      }
      return country.text
    }
  },

  watch: {
    coupon() {
      this.getPreview()
    },

    vatNumber() {
      this.getPreview()
    }
  },

  mounted() {
    if (this.$route.query.code) {
      // As a rule we should avoid accessing query params this deep into a
      // compontent, but it would need to be passed down so many levels that
      // is easily more confusing than this
      this.coupon = this.$route.query.code
    }

    Promise.all([
      this.getBilling(),
      // this.getPlans(),
      this.getProducts(),
      this.getSubscriptions()
    ])
    this.setUpStripe()
  },

  destroyed() {
    this.card.destroy('card')
  },

  methods: {
    /**
     * Still required for legacy plans
     */
    getPlans() {
      if (!this.havePlansLoaded) {
        return this.$store.dispatch('plans/getPlans')
      }
    },

    /**
     * Fetch the products if we need to get them
     */
    getProducts() {
      if (!this.haveProductsLoaded) {
        return this.$store.dispatch('products/getProducts', this.countryCode)
          .then(() => {
            this.countryCode = this.productsCountryCode
            this.getPreview()
          })
      }

      this.countryCode = this.productsCountryCode
      this.getPreview()
    },

    /**
     * Fetch the billing data
     */
    getBilling() {
      return billingApi.billing(this.organisationId)
        .then(response => {
          this.billing = response.data
        })
    },

    /**
     * Triggered when the user updates their billing details
     */
    updateBillingComplete() {
      this.billing = null
      return billingApi.billing(this.organisationId)
        .then(response => {
          this.billing = response.data
        })
    },

    /**
     * Fetch the subscriptions
     */
    getSubscriptions() {
      if (this.haveSubscriptionsLoaded) {
        return
      }
      this.$store.dispatch('subscriptions/getSubscriptions')
    },

    /**
     * Load in a preview for the plan, country and coupon
     */
    getPreview() {
      this.preview = null

      return billingApi.preview(this.organisationId, {
        stripeId: this.stripeId,
        countryCode: this.countryCode,
        coupon: this.coupon,
        vatNumber: this.vatNumber
      })
        .then(response => {
          this.preview = response.data

          this.cardState = this.cardStates.READY
        })
    },

    /**
     * Trigger confirmation of the new plan
     */
    processForm() {
      this.isProcessing = true
      this.errorMessage = null

      if (!this.billing || (this.billing && !this.billing.card)) {
        return this.submitCard()
          .then(response => {
            if (!response) {
              // No response means that there was an issue with the card
              this.isProcessing = false
              if (!this.errorMessage) {
                // The error message may have already been set be a higher-up
                // function – but if not then display a default message
                this.errorMessage = 'Sorry, there was a problem. Please try again'
              }
              return
            }
            return this.confirmPlan()
          })
          .catch(() => {
            this.isProcessing = false
            this.errorMessage = 'Sorry, there was a problem. Your card has not been charged'
          })
      }

      return this.confirmPlan()
    },

    /**
     * Trigger confirmation of the new plan
     */
    confirmPlan() {
      if (this.currentSubscription) {
        return this.changePlan()
      }

      return this.startPlan()
    },

    /**
     * Change plan if already using a plan
     */
    changePlan() {
      this.$store.dispatch('subscriptions/changeSubscription', {
        stripeId: this.stripeId,
        migration: this.migration,
        coupon: this.coupon,
        subscriptionId: this.currentSubscription.id,
        countryCode: this.countryCode,
        vatNumber: this.vatNumber
      })
        .then(action => {
          if (action.clientSecret) {
            return stripe
              .confirmCardPayment(action.clientSecret)
              .then(result => {
                if (result.error) {
                  console.error('Could not confirm card payment')
                  this.isProcessing = false
                  this.errorMessage = result.error.message
                  return
                }
                if (result.paymentIntent.status === 'succeeded') {
                  return this.subscriptionChangePlanCardConfirmed()
                }
              })
          } else if (action.requires === 'payment_method') {
            this.errorMessage = `Your payment method previously couldn’t be authorised. Please contact ${this.supportEmail} for further help.`
            return
          }
          this.subscriptionChangePlanCardConfirmed()
        })
        .catch(error => {
          this.isProcessing = false
          this.errorMessage = 'Sorry, there was an issue updating your subscription'
          throw error
        })
    },

    subscriptionChangePlanCardConfirmed() {
      this.triggerEvents()

      this.$store.dispatch('subscriptions/getSubscriptions')
        .then(() => {
          this.isProcessing = false
          this.changePlanActionConfirmed = true
        })
    },

    /**
     * Sets up Stripe Elements
     */
    setUpStripe() {
      this.card = elements.create('card', {
        style: elementStyle,
        iconStyle: 'solid'
      })
      this.card.mount('#card')

      this.listenForErrors()
    },

    /**
     * Listen for and store errors
     */
    listenForErrors() {
      this.card.addEventListener('change', ({ complete, error }) => {
        this.cardComplete = complete
        if (error) {
          this.cardError = error.message
          return
        }
        this.cardError = null
      })
    },

    /**
     * No active plan, start a new subscription
     */
    startPlan() {
      this.$store.dispatch('subscriptions/createSubscription', {
        stripeId: this.stripeId,
        migration: this.migration,
        coupon: this.coupon,
        countryCode: this.countryCode,
        vatNumber: this.vatNumber
      })
        .then(action => {
          if (action.clientSecret) {
            return stripe
              .confirmCardPayment(action.clientSecret)
              .then(result => {
                if (result.error) {
                  console.error('Could not confirm card payment')
                  this.isProcessing = false
                  this.errorMessage = result.error.message
                  return
                }
                if (result.paymentIntent.status === 'succeeded') {
                  this.subscriptionCardConfirmed()
                  return
                }
                console.error('Did not get status from card payment')
              })
          } else if (action.requires === 'payment_method') {
            this.errorMessage = `Your payment method previously couldn’t be authorised. Please contact ${this.supportEmail} for further help.`
            return
          }
          this.subscriptionCardConfirmed()
        })
        .catch(error => {
          this.isProcessing = false
          this.errorMessage = 'Sorry, there was an issue processing your subscription'
          throw error
        })
    },

    /**
     * Sets the plan data
     */
    submitCard() {
      console.log('💳 Submit card to Stripe')

      return stripe.createPaymentMethod({
        type: 'card',
        card: this.card,
        billing_details: {
          name: this.cardholderName
        }
      })
        .then(result => {
          if (result.error) {
            console.error(result.error)
            this.isProcessing = false
            this.errorMessage = 'There was an error processing the card'
            return
          }
          console.log('💳 Save card response')
          return subscriptionsApi
            .createCustomer(
              this.organisationId,
              this.userId,
              result.paymentMethod,
              this.countryCode
            )
            .catch(error => {
              this.isProcessing = false
              if (error.response && error.response.data) {
                this.errorMessage = error.response.data.data.errorMessage
                return
              }
              throw error
            })
        })
    },

    /**
     * Card has been authed
     *
     * @param {string} paymentIntentId
     */
    subscriptionCardConfirmed() {
      this.triggerEvents()

      this.$store.dispatch('subscriptions/getSubscriptions')
        .then(() => {
          this.isProcessing = false
          this.subscriptionConfirmed = true
        })
    },

    /**
     * Trigger all events for analytics
     *
     * @param {string} paymentIntentId
     */
    triggerEvents(paymentIntentId) {
      // Facebook Pixel
      if (window.fbq) {
        window.fbq('track', 'Purchase', {
          currency: this.plan.currency,
          value: this.preview.totalAmount / 100
        })
      }

      bingSubscription(this.preview.totalAmount / 100, this.plan.currency)

      // Google Adwords
      if (this.$gtm) {
        const gaAdWordsEvent = {
          event: 'Google Ads Conversion Tracking',
          conversionValue: this.preview.totalAmount / 100,
          conversionCurrencyCode: this.plan.currency
        }
        // @TODO 2021-08-09 Remove when tested
        // console.log('📊 Google Ads tracking', gaAdWordsEvent)
        this.$gtm.trackEvent(gaAdWordsEvent)
      }

      // Google Analytics events
      if (this.$gtm) {
        this.$gtm.trackEvent({
          event: 'event',
          eventCategory: 'Employer',
          eventAction: 'Paid',
          eventLabel: this.plan.stripeId
        })

        const transactionId = this.organisationId + '-' + this.plan.stripeId

        // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#select_item
        this.$gtm.trackEvent({
          event: 'ga4_purchase',
          currency: this.plan.currency,
          transaction_id: transactionId,
          value: this.preview.totalAmount / 100,
          items: [{
            item_id: this.plan.stripeId,
            item_name: this.plan.name
          }]
        })

        const gaEcommerceEvent = {
          event: 'purchase',
          category: 'Ecommerce',
          action: 'Purchase',
          ecommerce: {
            currencyCode: this.plan.currency,
            purchase: {
              actionField: {
                id: transactionId,
                revenue: this.preview.totalAmount / 100
              },
              products: [{
                name: this.plan.name,
                id: this.plan.stripeId,
                price: this.preview.totalAmount / 100,
                brand: 'Picked Group Limited',
                quantity: 1
              }]
            }
          }
        }
        // @TODO 2021-07-29 Remove when tested
        // console.log('📊 Google Analytics Ecommerce tracking', gaEcommerceEvent)
        this.$gtm.trackEvent(gaEcommerceEvent)
      }
    }
  }
}
</script>
