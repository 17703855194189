/**
 * Triggers a Bing purchase event
 *
 * @param {number} value
 * @param {string} currencyCode
 */
export const bingPurchase = (value, currencyCode) => {
  window.uetq = window.uetq || []
  window.uetq.push(
    'event',
    'Purchase',
    {
      'event_category': 'Purchase',
      'revenue_value': value,
      'currency': currencyCode
    }
  )
  console.log('📈 Bing Purchase')
}

/**
 * Triggers a Bing subscription purchase event
 *
 * @param {number} value
 * @param {string} currencyCode
 */
export const bingSubscription = (value, currencyCode) => {
  window.uetq = window.uetq || []
  window.uetq.push(
    'event',
    'Subscribe',
    {
      'event_category': 'Subscribe',
      'revenue_value': value,
      'currency': currencyCode
    }
  )
  console.log('📈 Bing Subscription')
}

/**
 * Triggers a Bing custom event
 *
 * @param {string} category
 */
export const bingSignUpEvent = (category) => {
  window.uetq = window.uetq || []
  window.uetq.push(
    'event',
    'Sign up',
    {
      'event_category': category
    }
  )
  console.log('👩‍💻 Bing Sign Up')
}
